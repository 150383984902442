<template>
	<div class="mdhealtFormHeader puiformheader">
		<v-layout wrap>
			<v-flex xs12 md12>
				<v-row dense>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="this.$t('mdhealt.portcallnumber')" :value="getPortcallNumber"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="this.$t('header.mships.nameofship')" :value="getShipName"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="this.$t('mdhealt.ship.imo')" :value="getShipIMO"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="this.$t('mdhealt.ship.flag')" :value="getFlag"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="this.$t('mdhealt.portofcall.title')" :value="getPort"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="this.$t('mdhealt.illpersons')" :value="getIlls"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="this.$t('mdhealt.deceaseds')" :value="getDeceaseds"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="this.$t('mdhealt.status')" :value="getStatus"></pui-field>
					</v-col>
				</v-row>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'mdhealt-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'mdhealt'
		};
	},

	computed: {
		getPortcallNumber() {
			return this.model && this.model.visitid ? this.model.visitid : '';
		},
		getShipName() {
			return this.model && this.model.shipname ? this.model.shipname : '';
		},
		getShipIMO() {
			return this.model && this.model.shipimo ? this.model.shipimo : '';
		},
		getFlag() {
			return this.model && this.model.shipflagdesc ? this.model.shipflagdesc : '';
		},
		getPort() {
			return this.model && this.model.pcportdesc ? this.model.pcportdesc : '';
		},
		getIlls() {
			return this.model && this.model.totalillpersons ? this.model.totalillpersons : '0';
		},
		getDeceaseds() {
			return this.model && this.model.totaldeaths ? this.model.totaldeaths : '0';
		},
		getStatus() {
			return this.model && this.model.statusdescription ? this.model.statusdescription : '';
		}
	}
};
</script>
